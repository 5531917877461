/* eslint-disable no-underscore-dangle */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Hero from 'components/Hero'
import Layout from 'components/Layout'
import VillageOpenHours from 'components/OpenHours/VillageOpenHours'
import StacklaWidget from 'components/StacklaWidget'
import renderContent from 'utils/componentRenderer'
import { constructUrlPath } from 'utils/urlUtils'

import { filterRepeatedContent } from 'utils/contentUtils'
import { Spacing50 } from 'styles/sharedStyle'

const nonRepeatedContentTypes = ['ContentfulCompGridHomepageGrd01']

const HomePageTemplateT01 = (props) => {
  const {
    data: {
      openingHoursExceptions,
      openingHoursLabels,
      page: { content, hero, village, stacklaCarousel, jsonLdSchema },
    },
    pageContext: {
      nodeLocale,
      pageLevel,
      translatedSlugs,
      translatedServices,
      villageSlug,
    },
  } = props

  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        opacity: media.opacity,
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        name: media.name,
      }
    : {}

  const ctaUrl =
    hero && hero.ctaUrl
      ? constructUrlPath({
          nodeLocale,
          pageLevel,
          translatedServices,
          translatedSlugs,
          url: hero.ctaUrl,
          villageSlug,
        })
      : null

  const stacklaCtaUrl = stacklaCarousel
    ? constructUrlPath({
        nodeLocale,
        pageLevel,
        translatedServices,
        translatedSlugs,
        url: stacklaCarousel.ctaUrl,
        villageSlug,
      })
    : null

  const heroProps = hero
    ? {
        eyebrow: hero.eyebrow,
        content: hero.headline,
        fullScreen: true,
        image: heroImage,
        video: heroVideo,
        ctaUrl,
        ctaLabel: hero.ctaLabel,
        isCondensed: hero.isCondensed,
        opacity: hero.opacity,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  const filteredContent =
    !!content && filterRepeatedContent(content, nonRepeatedContentTypes)

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>

      {heroProps && (
        // @ts-ignore
        <Hero {...heroProps} />
      )}

      {!!village && !!village.openingHours && (
        // @ts-ignore
        <VillageOpenHours
          background="White"
          village={village}
          openingHoursLabels={openingHoursLabels}
          openingHoursExceptions={openingHoursExceptions}
        />
      )}

      {renderContent(filteredContent, props)}

      <Spacing50 />

      {!!stacklaCarousel && !!stacklaCarousel.code && (
        <StacklaWidget
          code={stacklaCarousel.code.code}
          eyebrow={stacklaCarousel.eyebrow}
          headline={stacklaCarousel.headline}
          ctaLabel={stacklaCarousel.ctaLabel}
          ctaUrl={stacklaCtaUrl}
        />
      )}
    </Layout>
  )
}

HomePageTemplateT01.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
    openingHoursExceptions: PropTypes.shape({
      exceptions: PropTypes.instanceOf(Array),
    }),
    openingHoursLabels: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.instanceOf(Object),
        })
      ),
    }),
    page: PropTypes.instanceOf(Object),
  }).isRequired,
  pageContext: PropTypes.shape({
    nodeLocale: PropTypes.string,
    pageLevel: PropTypes.string,
    translatedServices: PropTypes.instanceOf(Object),
    translatedSlugs: PropTypes.instanceOf(Object),
    villageSlug: PropTypes.string,
  }).isRequired,
}

// eslint-disable-line no-unused-vars
export const VillageIndexPageQuery = graphql`
  query(
    $id: String!
    $openingHoursId: String
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $pageTypeSlug: String
    $villageSlug: String
    $needContactIcons: Boolean = false
  ) {
    page: contentfulPageTemplateHomeT01(id: { eq: $id }) {
      jsonLdSchema {
        jsonLdSchema
      }
      header {
        ...header
      }
      footer {
        ...footer
      }
      locale: node_locale
      hero {
        title
        headline
        isCondensed
        opacity
        eyebrow
        ...heroMediaQuery
        ctaUrl
        ctaLabel
      }
      village {
        villageSlug: slug
        name
        currency
        home: page_template_home_t01 {
          ...headerFooterVillage
        }
        openingHours {
          ...villageOpeningHours
        }
        labels: label_brand_details_lab01 {
          openingLabel
          closingLabel
          reopeningLabel
          ...virtualShoppingIconsAndLabelsQuery
        }
        defaultLocale
        openingStatusLabel
      }
      content {
        __typename
        ... on Node {
          ...multipleComponentsT01
        }
      }
      hideFromSearchEngine
      pageTitle
      pageDescription
      stacklaCarousel {
        code {
          code
        }
        eyebrow
        headline
        ctaLabel
        ctaUrl
      }
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          ...contentfulOpeningHoursExceptions
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
    allMembershipPopUps: allContentfulCompMembershipPopUp(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
        active: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          ...membershipPopUp
        }
      }
    }

    villages: allContentfulEntityVillageEnt01(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      nodes {
        name
        slug
        externalVillageLink
        node_locale
        city
        openingStatusLabel
        cardImage {
          portrait {
            file {
              url
            }
          }
        }
        homePages: page_template_home_t01 {
          defaultLocale
          header {
            languageGroup {
              languages {
                locale
              }
            }
          }
        }
      }
    }
  }
`

export default HomePageTemplateT01
